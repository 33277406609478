import { VSCode } from 'assets';

export const constants = {
  GITHUB_URL: 'https://github.com/',
  SCM_URL_FORMAT: 'https://{scmVendor}.com/',
  PLAN_SLUG: {
    MY_PLAN: 'jit-plan',
    MVS: 'plan-mvs-for-cloud-app',
    GH_MARKETPLACE: 'plan-github-marketplace',
    SOC2: 'plan-soc2',
    ISO_27001: 'plan-iso-27001',
    SERVERLESS: 'plan-owasp-serverless-top-10',
    FTR: 'plan-aws-ftr',
  },
  INTEGRATIONS: {
    GITHUB: 'github',
    AWS: 'aws',
    SLACK: 'slack',
    GITLAB: 'custom.gitlab',
  },
  PLAN_ITEMS_SLUG: { // Specific slug of plan items that are still needed for some business logic
    SECRETS: 'item-secret-detection',
    ZAP_WEB: 'item-web-app-scanner',
    ZAP_API: 'item-api-security',
    GITHUB_BRANCH_PROTECTION: 'item-branch-protection-scm',
    GITHUB_BRANCH_SET_CONFIG: 'item-branch-set-config',
    PROWLER: 'item-runtime-misconfiguration-detection',
    SBOM: 'item-software-bill-of-materials',
  },
  routes: {
    ONBOARDING: 'onboarding',
    OVERVIEW: 'overview',
    FINDINGS: 'findings',
    PLAN: 'plan',
    PLANS: 'plans',
    RESOURCES: 'resources',
    MATRIX: 'matrix',
    INTEGRATIONS: 'integrations',
    POLICIES: 'policies',
    PIPELINES: 'pipelines',
    ACTIONS: 'actions',
    TEAMS: 'teams',
    SECURITY_MAPPING: 'security-mapping',
    RESOURCE: 'resource',
    THIRD_PARTY_AUTHENTICATION: 'third-party-authentication',
    QUICKSTART: 'quickstart',
    APPS_INTEGRATIONS: 'apps/integrations',
    pathParameters: {
      PIPELINE_ID: 'pipelineId',
      INSIGHT_PAGE: 'insightPage',
      SNAPSHOT_ID: 'snapshotId',
      ACTION_ID: 'actionId',
      PLAN_SLUG: 'planSlug',
      RESOURCE_ID: 'resourceId',
      PLAN_ITEM_SLUG: 'planItemSlug',
      TEAM_ID: 'teamId',
    },
    teamsPortal: {
      BASE_ROUTE: 'teams-portal',
      WELCOME: 'welcome',
      GUIDE: 'guide',
      LEADERBOARD: 'leaderboard',
      PORTAL_TEAMS: 'teams',
      PORTAL_RESOURCES: 'resources',
      PORTAL_PLAN_ITEMS: 'factors',
      TOP_FINDINGS: 'top-findings',
    },
    insights: {
      BASE_ROUTE: 'insights',
      PULL_REQUESTS: 'pull-requests',
      DEPLOYMENTS: 'deployments',
      PERFORMANCE: 'performance',
      SNAPSHOT: 'snapshots',
      SNAPSHOT_NOT_FOUND: 'snapshot-not-found',
      SECURITY_IMPACT: 'security-impact',
      SBOM: 'sbom',
    },
    quickStartGuide: {
      BASE_ROUTE: 'quickstart',
    },
    risks: {
      BASE_ROUTE: 'risks',
      BACKLOG: 'backlog',
      RESOURCES_LIST: 'list',
      RESOURCES: 'resources',
    },
    LOGOUT: 'logout',
  },
  referrals: {
    itemCodeVulnerability: 'item-code-vulnerability',
  },
  DEFAULT_REFERRAL: '',
  PLAN_ITEM_REF_PREFIX: 'jitsecurity-controls/jit-plans/items',
  PLAN_ITEM_REF_SUFFIX: '.yml@latest',
  INITIAL_PATHNAME_KEY: 'initial_pathname',
  TEMP_PLAN_KEY: 'temp_plan',
  GITHUB_INSTALLATION_REDIRECT_PATH: '/apps/integrations',
  INVITATION_TOKEN_KEY: 'invitationToken',
  STATE_TOKEN_KEY: 'stateToken',
  UNAUTHORIZED_MESSAGE: 'User is not authorized to access this resource with an explicit deny',
  GOAL_PLAN_KEY: 'goalPlan',
  SHOULD_SIGNUP_KEY: 'shouldSignup',
  RECENT_LOCATION_ROUTE_KEY: 'recentLocationRoute',
  IS_SIGNUP_KEY: 'isSignupFlow',
  IS_REDIRECTED: 'isRedirected',
  OPEN_THIRD_PARTY: 'openThirdParty',
  ONBOARDING_STATE_KEY: 'onboardingState',
  PLAN_ITEM: 'plan_item',
  EMPTY_PATH: '/',
  ACCOUNT_SOCIAL_SUCCESS_PATH: '/account/social/success',
  ACCOUNT_SAML_SUCCESS_PATH: '/account/saml/callback',
  ACCOUNT_OIDC_SUCCESS_PATH: '/account/oidc/callback',
  SIGN_UP_PATH: '/account/sign-up',
  LOGOUT_PATH: '/logout',
  LOGIN_PATH: '/login',
  GITHUB: 'github',
  GITLAB: 'gitlab',
  TENANT_ID: 'tenant_id',
  JIT_JWT: 'jit_jwt',
  INVALID_DATE: 'Invalid Date',
  FINDINGS_TABLE_PAGE_ITEMS_AMOUNT: 20,
  PIPELINES_TABLE_PAGE_ITEMS_AMOUNT: 5000,
  TABLE_PAGE_ITEMS_AMOUNT: 20,
  COPY_TO_CLIPBOARD_DELAY: 3500,
  time: {
    SECOND: 1000,
    MINUTE: 60 * 1000,
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
    MONTH: 4 * 7 * 24 * 60 * 60 * 1000,
  },
  longTimeout: 10000,
  mediumTimeout: 5000,
  shortTimeout: 1000,
  MAX_SUPPORTED_HEADERS_COUNT: 3,
  DAY_IN_MILLISECONDS: 1000 * 60 * 60 * 24,
  SMALL_SCREEN_WIDTH: 1400,
  TOAST_SECONDS_TO_CLOSE: 5,
  THIRD_PARTY_AUTHENTICATION: {
    vscode: {
      type: 'IDE',
      name: 'vscode',
      icon: VSCode,
      redirect_message: {
        title: 'Welcome to Jit IDE Extension!',
        subtitle: 'You have successfully authenticated and can now close this window.',
      },
      get_redirect_url: (isMarketplace = false) => (isMarketplace ? 'vscode://jitsecurity.jitsecurity' : 'vscode://jitsecurity.jit-secured-ide'),
    },
  },
  LOADER_TIMEOUT: 20000,
};
