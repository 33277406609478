/* eslint-disable max-statements */

import { AccessDenied } from '../components/AccessDenied';
import { PERMISSIONS } from '../constants';

import { constants } from 'globalConstants';
import { ActionsPageWrapper } from 'pages/ActionsPage/ActionPageWrapper';
import { FindingsPageWrapper } from 'pages/FindingsPage/FindingsPageWrapper';
import { InsightsPage, PullRequestsPage } from 'pages/InsightsPage';
import { DeploymentsPageWrapper } from 'pages/InsightsPage/SubPages/DeploymentsPage/DeploymentsPageWrapper';
import { SnapshotNotFound } from 'pages/InsightsPage/SubPages/PerformancePage/components/SnapshotNotFound/SnapshotNotFound';
import { PerformancePageWrapper } from 'pages/InsightsPage/SubPages/PerformancePage/PerformancePageWrapper';
import { SecurityImpact } from 'pages/InsightsPage/SubPages/SecurityImpact/SecurityImpact';
import { IntegrationsPageWrapper } from 'pages/IntegrationsPage/IntegrationsPageWrapper';
import { OverviewPage } from 'pages/OverviewPage/OverviewPage';
import { NewPipelinesPageWrapper } from 'pages/PipelinesPage/NewPipelinesPageWrapper';
import { PipelineDetailsPage } from 'pages/PipelinesPage/PipelineDetailsPage/PipelineDetailsPage';
import { PlanPageWrapper } from 'pages/PlanPage/PlanPageWrapper';
import { PoliciesPage } from 'pages/PoliciesPage/PoliciesPage';
import { QuickStartGuide } from 'pages/QuickStartGuide/QuickStartGuide';
import { ResourcePage } from 'pages/Resources/ResourcePage/ResourcePage';
import { ResourcesDashboardPageWrapper } from 'pages/Resources/ResourcesDashboardPage/ResourcesDashboardPageWrapper';
import { ResourcesPage } from 'pages/Resources/ResourcesPage/ResourcesPage';
import { SbomPageWrapper } from 'pages/SbomPage/SbomPageWrapper';
import { SecurityMappingPage } from 'pages/SecurityMappingPage/SecurityMappingPage';
import { SecurityPlansPage } from 'pages/SecurityPlansPage/SecurityPlansPage';
import { ResourcePlanItemsStatusesPageWrapper } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/ResourcePlanItemsStatusesPageWrapper';
import { TeamDetailsPageWrapper } from 'pages/TeamsPage/SubPages/TeamDetailsPage/TeamDetailsPageWrapper';
import { TeamsPageWrapper } from 'pages/TeamsPage/SubPages/TeamsPage/TeamsPageWrapper';
import { FindingsPage } from 'pages/TeamsPortal/FindingsPage/FindingsPage';
import { GuidePage } from 'pages/TeamsPortal/GuidePage/GuidePage';
import { LeaderboardPage } from 'pages/TeamsPortal/LeaderboardPage/LeaderboardPage';
import { ResourcePage as TeamPortalResourcePage } from 'pages/TeamsPortal/ResourcePage/ResourcePage';
import { TeamPage } from 'pages/TeamsPortal/TeamPage/TeamPage';
import { WelcomePage } from 'pages/TeamsPortal/WelcomePage/WelcomePage';

const {
  FINDINGS,
  INTEGRATIONS,
  POLICIES,
  PIPELINES,
  ACTIONS,
  PLAN,
  OVERVIEW,
  PLANS,
  TEAMS,
  RESOURCE,
  SECURITY_MAPPING,
  QUICKSTART,
  APPS_INTEGRATIONS,
  insights: {
    BASE_ROUTE: BASE_INSIGHTS_ROUTE,
    DEPLOYMENTS,
    PERFORMANCE,
    PULL_REQUESTS,
    SNAPSHOT,
    SNAPSHOT_NOT_FOUND,
    SECURITY_IMPACT,
    SBOM,
  },
  teamsPortal: {
    BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE,
    WELCOME,
    GUIDE,
    LEADERBOARD,
    PORTAL_TEAMS,
    PORTAL_RESOURCES,
    PORTAL_PLAN_ITEMS,
    TOP_FINDINGS,
  },
  risks: {
    BASE_ROUTE: BASE_RISKS_ROUTE,
    BACKLOG,
    RESOURCES_LIST,
    RESOURCES,
  },
} = constants.routes;

const {
  PIPELINE_ID,
  SNAPSHOT_ID,
  PLAN_SLUG,
  TEAM_ID,
  RESOURCE_ID,
  PLAN_ITEM_SLUG,
} = constants.routes.pathParameters;

const buildManagementPlatformRoutes = (
  uiShowSbomPage: boolean,
  uiShowTheLoopPage: boolean,
  uiShowSecurityChampionQuickGuide: boolean,
  initialOnboardingCompleted: boolean,
  uiShowResourcesPage: boolean,
  uiShowPoliciesPage: boolean,
): Record<string, JSX.Element> => {
  const routes: Record<string, JSX.Element> = {
    [`/${PIPELINES}`]: <NewPipelinesPageWrapper />,
    [`/${PLAN}`]: <SecurityPlansPage />,
    [`/${PLAN}/:${PLAN_SLUG}`]: <PlanPageWrapper />,
    [`/${PLANS}`]: <SecurityPlansPage />,
    [`/${PLANS}/:${PLAN_SLUG}`]: <PlanPageWrapper />,
    [`/${PIPELINES}/:${PIPELINE_ID}`]: <PipelineDetailsPage />,
    [`/${ACTIONS}`]: <ActionsPageWrapper />,
    [`/${TEAMS}`]: <TeamsPageWrapper />,
    [`/${TEAMS}/:${TEAM_ID}/${RESOURCE}/:${RESOURCE_ID}`]: <ResourcePlanItemsStatusesPageWrapper />,
    [`/${TEAMS}/:${TEAM_ID}`]: <TeamDetailsPageWrapper />,
    [`/${RESOURCE}/:${RESOURCE_ID}`]: <ResourcePlanItemsStatusesPageWrapper />,
    [`/${BASE_INSIGHTS_ROUTE}/${SECURITY_IMPACT}`]: <InsightsPage page={<SecurityImpact />} pageKey={SECURITY_IMPACT} />,
    [`/${BASE_INSIGHTS_ROUTE}/${DEPLOYMENTS}`]: <InsightsPage page={<DeploymentsPageWrapper />} pageKey={DEPLOYMENTS} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PULL_REQUESTS}`]: <InsightsPage page={<PullRequestsPage />} pageKey={PULL_REQUESTS} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}`]: <InsightsPage page={<PerformancePageWrapper />} pageKey={PERFORMANCE} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT}/:${SNAPSHOT_ID}`]: <InsightsPage page={<PerformancePageWrapper />} pageKey={PERFORMANCE} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT_NOT_FOUND}`]: <InsightsPage page={<SnapshotNotFound />} pageKey={SNAPSHOT_NOT_FOUND} />,
    [`/${FINDINGS}`]: <FindingsPageWrapper />,
    [`/${INTEGRATIONS}`]: <IntegrationsPageWrapper />,
    [`/${OVERVIEW}`]: <OverviewPage />,
  };

  if (uiShowSbomPage) {
    routes[`/${BASE_INSIGHTS_ROUTE}/${SBOM}`] = <InsightsPage page={<SbomPageWrapper />} pageKey={SBOM} />;
  }

  if (uiShowTheLoopPage) {
    routes[`/${SECURITY_MAPPING}`] = <SecurityMappingPage />;
  }

  if (uiShowSecurityChampionQuickGuide) {
    if (!initialOnboardingCompleted) {
      // If the initial onboarding is not completed, the quick start guide should be the only accessible page
      return {
        [`/${QUICKSTART}`]: <QuickStartGuide />,
        [`/${APPS_INTEGRATIONS}`]: <QuickStartGuide />,
      };
    }
    routes[`/${QUICKSTART}`] = <QuickStartGuide />;
    routes[`/${APPS_INTEGRATIONS}`] = <QuickStartGuide />;
  }
  if (uiShowResourcesPage) {
    routes[`/${BASE_RISKS_ROUTE}/${BACKLOG}`] = <FindingsPageWrapper />;
    routes[`/${BASE_RISKS_ROUTE}/${RESOURCES}`] = <ResourcesDashboardPageWrapper />;
    routes[`/${BASE_RISKS_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`] = <ResourcesPage />;
    routes[`/${BASE_RISKS_ROUTE}/${RESOURCES}/${RESOURCES_LIST}/:${RESOURCE_ID}`] = <ResourcePage />;
  }

  if (uiShowPoliciesPage) {
    routes[`/${POLICIES}`] = <PoliciesPage />;
  }

  return routes;
};

const buildTeamPortalRoutes = (viewedWelcomeTeamsPage: boolean): Record<string, JSX.Element> => {
  const routes: Record<string, JSX.Element> = {
    [`/${BASE_TEAMS_PORTAL_ROUTE}/${GUIDE}/:${TEAM_ID}`]: <GuidePage />,
    [`/${BASE_TEAMS_PORTAL_ROUTE}/${GUIDE}`]: <GuidePage />,
    [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}`]: <TeamPage />,
    [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}/${PORTAL_RESOURCES}/:${RESOURCE_ID}`]: <TeamPortalResourcePage />,
    [`/${BASE_TEAMS_PORTAL_ROUTE}/${LEADERBOARD}`]: <LeaderboardPage />,
    [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}/${PORTAL_RESOURCES}/:${RESOURCE_ID}/${PORTAL_PLAN_ITEMS}/:${PLAN_ITEM_SLUG}`]:
  <FindingsPage />,
    [`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/:${TEAM_ID}/${TOP_FINDINGS}`]: <FindingsPage />,
  };

  if (!viewedWelcomeTeamsPage) {
    routes[`/${BASE_TEAMS_PORTAL_ROUTE}/${WELCOME}`] = <WelcomePage />;
  }

  return routes;
};

export const buildRoutes = (
  userPermissions: string[],
  uiShowSbomPage: boolean,
  uiShowTheLoopPage: boolean,
  viewedWelcomeTeamsPage: boolean,
  uiShowSecurityChampionQuickGuide: boolean,
  initialOnboardingCompleted: boolean,
  uiShowResourcesPage: boolean,
  uiShowPoliciesPage: boolean,
): Record<string, JSX.Element> => {
  let routes: Record<string, JSX.Element> = {
    unauthorized: <AccessDenied />,
  };

  if (userPermissions.includes(PERMISSIONS.MANAGEMENT_PLATFORM)) {
    routes = {
      ...routes,
      ...buildManagementPlatformRoutes(uiShowSbomPage, uiShowTheLoopPage, uiShowSecurityChampionQuickGuide, initialOnboardingCompleted, uiShowResourcesPage, uiShowPoliciesPage),
    };
  }

  if (userPermissions.includes(PERMISSIONS.TEAMS_PORTAL)) {
    routes = {
      ...routes,
      ...buildTeamPortalRoutes(viewedWelcomeTeamsPage),
    };
  }

  return routes;
};
