import { FC, useMemo } from 'react';

import styles from './ResourceTags.module.scss';
import { calcTagColor, TAG_NAMES_TO_HIDE } from './utils';

import { JitTag } from 'components/JitTag/JitTag';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { Tag } from 'types/interfaces/Tag';
import { useCalculateMaxTagsInContainer } from 'utils/hooks/useCalculateMaxTagsInContainer';

interface Props {
  tags: Tag[];
  maxWidth?: number | string;
}

export const ResourceTags: FC<Props> = ({
  tags,
  maxWidth = '100%',
}) => {
  const filteredTags = tags.filter(
    (tag) => !TAG_NAMES_TO_HIDE.includes(tag.name),
  );

  const tagStrings = useMemo(() => filteredTags.map((tag) => `${tag.name}: ${tag.value}`), [filteredTags]);

  const {
    containerRef,
    actualWidth,
    tagsToShow,
    hiddenTags,
  } = useCalculateMaxTagsInContainer(tagStrings, maxWidth);

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      style={{
        maxWidth,
        width: actualWidth,
      }}
    >
      {tagsToShow.map((tag) => (
        <JitTag
          key={tag}
          color={calcTagColor(tag)}
          text={tag}
        />
      ))}

      {hiddenTags.length > 0 && (
        <JitTooltip
          followCursor={false}
          placement='top'
          title={hiddenTags.join(', ')}
        >
          <JitTag
            key='more'
            color={colors.iris}
            text={`+${hiddenTags.length}`}
            withBorder
          />
        </JitTooltip>
      )}
    </div>
  );
};
